import(/* webpackMode: "eager", webpackExports: ["Space","Row","Col","Button"] */ "__barrel_optimize__?names=Button,Col,Row,Space!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/antd/lib/typography/Paragraph.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/antd/lib/typography/Text.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/antd/lib/typography/Title.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/CombDivider/style.css");
